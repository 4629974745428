<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3 d-flex justify-center" v-if="dataSet">
      <div>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              solo
              dense
              type="search"
              autocomplete="off"
              label="Search Article"
              rounded
              append-icon="mdi-magnify"
              hide-details
              v-model="find"
              background-color="white"
              class="mb-3"
              @keyup="fetchData"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <div class="pointbreak pa-3 mb-3"></div> -->
        <div class="pointbreak pa-2">
          <div
            class="d-flex mb-3 article pa-2"
            v-for="(item, i) in dataSet"
            :key="i"
            @click.prevent="toDetail(item)"
          >
            <img
              :src="`${env}/artikel/upload/${item.user_id}/${item.image}`"
              height="150px"
              width="150px"
              style="object-fit: cover"
              class="rounded-lg mr-3"
              alt=""
            />
            <div
              class="d-flex flex-column justify-space-between"
              style="flex: 1"
            >
              <div>
                <h3 v-snip="2">
                  {{ item.judul }}
                </h3>
                <p v-snip="3" class="small_txt mb-0" v-html="item.isi"></p>
              </div>
              <div class="d-flex justify-end">
                <v-btn text color="blue">
                  <v-icon>mdi-heart</v-icon>
                  {{ item.likes }}
                </v-btn>
                <v-btn text color="blue">
                  <v-icon>mdi-eye</v-icon>
                  {{ item.views }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "userpoblicarticle",
  computed: {
    ...mapState({
      env: state => state.API_URL
    })
  },
  data() {
    return {
      loading: false,
      dataSet: null,
      page: 1,
      find: ""
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    toDetail(item) {
      this.$router.push(`/article/${item.id}/view`);
    },
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch("library/publicArticle", {
          page: this.page,
          limit: 10,
          find: this.find
        })
        .then(data => {
          this.dataSet = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.pointbreak {
  width: 80%;
  background: #fff;
  border-radius: 8px;
}

.article:hover {
  background: #f3e5f5;
  border-radius: 8px;
  cursor: pointer;
}
</style>
